// We use scss mixins here because we need to duplicate these values
// in both the prefers-color-scheme media query (for when the user 
// has not selected a color scheme and so we are using the system
// default, and wew also need to put it in the body.light-theme and
// and body.dark-theme classes for when the user has selected a mode
// using the UI.
@mixin light-color-scheme() {
  --background-color: #FEFEFE;
  --border-color: black;
  --blue: #7384B2;
  --orange-highlight: #F87575;
  --text-color: #333333;
  --link-underline-color: #CCCCCC;
  --active-icon-color: #333333;
  --inactive-icon-color: #CCCCCC;
}

@mixin dark-color-scheme() {
  --background-color: #181818;
  --border-color: white;
  --blue: #7384B2;
  --orange-highlight: #F87575;
  --text-color: #888888;
  --link-underline-color: #CCCCCC;
  --active-icon-color: #888888;  
  --inactive-icon-color: #CCCCCC;
}

@media (prefers-color-scheme: light) {
  :root {
    @include light-color-scheme;
  }

  body {
    background-color: var(--background-color);
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    @include dark-color-scheme;
  }

  body {
    background-color: var(--background-color);
  }

}

body.light-theme {
  @include light-color-scheme;
  background-color: var(--background-color);
}

body.dark-theme {
  @include dark-color-scheme;
  background-color: var(--background-color);
}

#footer {
  margin: 60px 0 30px 0;
}

#color-scheme-toggle-container {
  display: flex;
  justify-content: right;
}

#color-scheme-toggle {
  width: 50px;
  -webkit-appearance: none;
  background: transparent;
}

#color-scheme-toggle::-webkit-slider-runnable-track {
  outline: 1px solid var(--inactive-icon-color);
  -webkit-border-radius: 20px;
  border-radius: 5px;  
}

input[type="range"]::-webkit-slider-thumb {
  // -webkit-appearance: none; /* Override default look */
  //  appearance: none;
  //  margin-top: -12px; /* Centers thumb on the track */

  //  /*custom styles*/
  //  background-color: #5cd5eb;
  //  height: 1rem;
  //  width: 1rem;

  // background-color: var(--inactive-icon-color);
}

#color-scheme-toggle::-moz-range-track {
  height: 15px;
  outline: 1px solid var(--inactive-icon-color);
  -webkit-border-radius: 5px;
  border-radius: 5px;   
}

input[type="range"]::-moz-range-thumb {
  /* border: none; /* Removes extra border that FF applies*/
  background-color: var(--inactive-icon-color);
}


svg path {
  fill: inherit;
}

#color-scheme-toggle-container svg path {
  fill: var(--inactive-icon-color);
}
 

html {
//  line-height: 32px;
}

/* This masks horizontal scroll bars from some invisible image overflow. */
html,body{
  overflow-x: hidden;
}

a img,
 :link img,
 :visited img {
  border: 0;
}

figure > img {
  margin-left: 50%;
  transform: translateX(-50%);
}

figure > figcaption {
  text-align: center;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ol,
ul {
  list-style: none;
}

blockquote {
  border-left: 5px solid var(--orange-highlight);
  padding: 1rem 2rem 0 2rem;
  margin: 0;
}

html,
body,
form,
fieldset {
  color: var(--text-color);
  font-family: garamond, georgia, Verdana, sans-serif;
  font-size: 1.125rem;
  text-rendering: optimizeLegibility;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6
{
  color: var(--blue);
  font-weight: bold;
  font-family: "ff-tisa-sans-web-pro", Arial, sans-serif;
}

small {
  color: var(--orange-highlight);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
// code,
ul,
ol,
dl,
address {
  margin: 0 0 1.5rem 0;
  padding: 0;
}

P {
  font-size: 1rem;
  margin: 0 0 1.5rem 0;
}

h1 {
  font-size: 1.5rem;
  margin: 0 0 1rem 0;
}

h2 {
  font-size: 1.25rem;
  margin-top: 0.5rem;
  margin-bottom: 0.333333rem;
}

h3 {
  font-size: 1rem;
  margin: 0rem;
}

pre,
code {
  font-family: Menlo, Monaco, Courier, Monospace;
  margin: 0;
  text-align: left;
  color: var(--text-color);
  white-space: pre;
//  line-height: 1rem;
}
#cheatsheet-table {
  margin-left: 50%;
  transform: translateX(-50%);
}

b,
strong {
  font-weight: bolder;
}

em {
  font-style: italic;
}

li,
dd {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

form label {
  cursor: pointer;
}

fieldset {
  border: none;
}

a {
  text-decoration: none;
  color: var(--blue);
}

a:link {
  border-bottom: 1px dotted var(--link-underline-color);
}

a:visited {}

#container {
  background-color: var(--background-color);
  margin: auto;
  max-width: 600px;
}

#body {
  margin: auto;
}

#header {
  display: inline-block;
  width: 100%;
  border-bottom: 1px solid var(--border-color);
  padding: 18px 0 18px 0;
  text-align: center;
}

#header-img {
  width: 240px;
}

#header h1 {
  font-size: 2.0rem;
  border-bottom: none;
  margin: 0;
  padding: 30px 0 10px 0;
}

#header h1 a {
  border-bottom: none;
}

#nav {
  list-style: none;
  margin: 0;
  padding: 0;
  margin: 0 0;
}

#nav li {
  display: inline;
  margin: 0;
  padding-right: 1rem;
}
#title {
  margin: 18px 0;
}

#title h1 {
  text-align: center;
}


.left {
  float: left;
}

.right {
  float: right;
}

hr {
  border: none 0;
  border-top: 1px solid var(--border-color);
  height: 0;
  margin: 0;
  padding: 0.5rem 0 0.5rem 0;
  width: 100%;
}

/* Center images in snippets */
.snippet img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}