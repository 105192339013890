@use "main";

/* See
https://css-tricks.com/a-complete-guide-to-dark-mode-on-the-web/
https://dev.to/whitep4nth3r/light-and-dark-mode-in-just-14-lines-of-css-424e
for dark mode guidance */
:root {
  color-scheme: light dark;
}

// @import "main";
